<template>
  <div class="about-page">
    <div class="rn-story-area d-flex bg_color--3" id="about">
      <div class="rn-story-right w-50 d-flex align-items-center">
        <div
          class="story-style--3 story-content text-left rn-plr--160 section-ptb-xl"
        >
          <div class="content text-left">
            <p>
              Bendruomenės įkūrėjas ir ilgametis pirmininkas Donatas Jaunius.
              Pradžioje savo veiklos, ji veikė kaip Sporto klubas.
              2008 metų sausio 30 dieną, visuotiniame susirinkime nutarta praplėsti veiklą, neapsiribojant sportu.
              Būtent nuo šios datos asociacija jau pradėjo veikti kaip "Saugų bendruomenė".
              Asociacijos narių tikslas, kad mūsų gyvenvietė būtų gražesnė, tvarkingesnė ir saugesnė, kad
              visiems Saugų gyventojams Saugose gyventi būtų įdomiau ir mieliau.
            </p>
            <p>
             Visus pritariančius šiai minčiai kviečiame įsijungti į mūsų gretas.
            </p>
            <p>
              Bendruomenės vadovai: 
              <br>
            Nuo 2011 m. iki  2014 m.  Eugenijus Judeikis.
              <br>
            Nuo 2014 m. iki 2016 m. - Mindaugas Nogaitis.
              <br>
            Nuo 2016 m. iki 2018 m. - Julija Jauniuvienė 
              <br>
            Nuo 2018 m. iki 2021 m.  - Aušra Judeikienė
              <br>
            Nuo 2021 m. iki dabar - Zigmantas Jaunius
              <br>
            </p>
            <div
              class="story-btn  wow newFadeInUp"
              data-wow-duration="1s"
              data-wow-delay="1000ms"
            >
              <router-link to="/susisiekti">Susisiekti</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Story Area -->
  </div>
</template>

<script>
  export default {
    components: {
    },
    data() {
      return {
        aboutSrc: require("../../assets/img/portfolio/about-image.jpg"),
        skillSrc: require("../../assets/img/portfolio/my-skill.jpg"),
      };
    },
  };
</script>

<style lang="scss" scoped>
  .about-page {
    .rn-skill-right {
      @media only screen and (max-width: 767px) {
        order: 2;
      }
    }
  }
</style>
